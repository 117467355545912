import {useEffect} from 'react'
import Router, {useRouter} from 'next/router'
import {parseCookies, setCookie} from 'nookies'
import "../styles/globals.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {SentryInit} from '../utils/sentry'
import {OntraInit} from '../utils/ontraport'
import "../styles/forms.css";

import("font-awesome/css/font-awesome.min.css");
import "@fontsource/libre-franklin/400.css"
import "@fontsource/libre-franklin/600.css"
import "@fontsource/libre-franklin/800.css"
import {GTMPageView} from "../utils/gtm";
import {ipEvent, pageViewEvent} from "../utils/events";
import {modernizerInit} from "../utils/modernizer";

SentryInit();
OntraInit();
modernizerInit();

function MyApp({Component, pageProps, err}) {

    const router = useRouter();

    const currentCookies = parseCookies();
    for (let a in router.query) {
        if (!(a in currentCookies) || !currentCookies[a] || currentCookies[a] === "") {
            setCookie(null, "irs-" + a, router.query[a], {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            })
        }
    }

    useEffect(() => {
        const handleRouteChange = (url) => {
            console.log("Route change completed");
            handleOptimize(url);
            GTMPageView(url);
            ipEvent().then();
        };

        const handleOptimize = (url) => {
            window && window.dataLayer && window.dataLayer.push({
                event: 'optimize.activate',
                page: url,
            });
            pageViewEvent("pageChange").then();
        }

        handleOptimize("");
        Router.events.on('routeChangeComplete', handleRouteChange);
        ipEvent().then();


        return () => {
            console.log("disabling events")
            Router.events.off('routeChangeComplete', handleRouteChange);
        };


    }, [])


    return <Component {...pageProps} err={err}/>
}


export default MyApp
