
export const getQueryStringParamByName = name =>{
    return getQueryStringParam(window.location.search, name);
}


export const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

export const getQueryStringParam = (query, param) =>{
    const params = getQueryStringParams(query);
    if(param in params){
        return params[param];
    }

    return "";
}


