

export const GTMPageView = (url) => {

    window && window.dataLayer && window.dataLayer.push({
        event: 'pageview',
        page: url,
    });

};
