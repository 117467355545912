import axios from "axios";
import {getQueryStringParamByName} from './qs';

function loadScript(src, async) {
    let script = document.createElement('script');
    script.src = src;
    script.async = async;
    document.body.append(script);
}

export function OntraInit() {
    if (process.browser && window) {

        const noPush = getQueryStringParamByName("nopush");
        if (noPush && noPush !== "" && noPush !== "undefined") {
            return;
        }

        window.OneSignal = window.OneSignal || [];
        window.OneSignal.push(function () {
            window.OneSignal.init({
                appId: process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID,
            })
        });

        localStorage.setItem('OneSignalAppId', process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID);
        window.OneSignal.push(function () {
            window.OneSignal.on('subscriptionChange', function (isSubscribed) {
                if (isSubscribed === true) {
                    window.OneSignal.getUserId().then(function (userId) {
                        localStorage.setItem('OneSignalUserId', userId);
                    }).catch(function (x) {
                        console.log("ontraport err", x);
                    });
                }
            })
        });

        loadScript("https://cdn.onesignal.com/sdks/OneSignalSDK.js", true)
    }
}
